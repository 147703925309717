import './Home.css';
import { useState } from "react";
import Banner from '../media/banner.png';
import EcoSys from '../media/eco-sys.png';
import Chart from '../media/token-chart.png';
import Sol from '../media/solana.png';
import LaunchApp from '../media/launch-app.png';
import FeatureImg from '../media/feature-img.png';
import Screenshot from '../media/screenshot.png';
import Payment from '../media/payment.png';
import Wallet from '../media/wallet.png';
import NFT from '../media/nft.png';
import Staking from '../media/staking.png';
import Partner1 from '../media/partner-1.png';
import Partner2 from '../media/partner-2.png';
import Partner3 from '../media/partner-3.png';
import Partner4 from '../media/partner-4.png';
import Partner5 from '../media/partner-5.png';
import PreFooter from '../media/pre-footer-bot.png';

function Home() {

    const [tokenCopied, setTokenCopied] = useState(false);

    const handleCopyToken = () => {
        const tokenAddressInput = document.getElementById('token-address');
        tokenAddressInput.select();
        document.execCommand('copy');
        setTokenCopied(true);

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setTokenCopied(false);
        }, 2000);
    };


    return (
        <>
            <section id="home" className='d-flex align-items-center'>

                <div class="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="intro">
                                <h1>Empowering Innovation</h1>
                                <p>Genius AI Game Engine Leading the Gaming Revolution</p>
                                <p>Crafting Tomorrow's Gaming Experience</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hero-img"><img src={Banner} alt="Banner" /></div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="ecosystem">
                <div class="container">

                    <div className="section-title"><h2 className='text-center'>GAG ECOSYSTEM</h2></div>

                    <div class="row d-flex align-items-center">

                        <div class="col-md-4 order-md-1 order-2">
                            <div className="ecosystem-box">
                                <span class="box-number">01</span>
                                <h6>$GAG & GENI Token</h6>
                                <p>Tokens are a versatile governance & utility token that drives Genius AI Game platform adoption and growth.</p>
                            </div>
                            <div className="ecosystem-box">
                                <span class="box-number">02</span>
                                <h6>AI Game Engine</h6>
                                <p>Develop cool games directly with the AI game engine and earn GAG coin rewards.</p>
                            </div>
                        </div>
                        <div class="col-md-4 text-center mb-4 order-md-2 order-1">
                            <img src={EcoSys} alt="Eco" class="ecosystem-image" />
                        </div>

                        <div class="col-md-4 order-md-3 order-3">
                            <div className="ecosystem-box-alt">
                                <span class="box-number-alt">03</span>
                                <h6>Staking Program</h6>
                                <p>Staking $GAG incentivizes users to support & secure the platform while earning rewards.</p>
                            </div>
                            <div className="ecosystem-box-alt">
                                <span class="box-number-alt">04</span>
                                <h6>DAO Voting</h6>
                                <p>$GAG enables holders to influence the ecosystem via a DAO, allowing them to suggest, vote on, and distribute funds.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tokenomics">
                <div className="container">
                    <div className="section-title"><h2 className='text-center'>$GAG TOKENOMICS</h2></div>

                    <div className="row">
                        <div className="col-md-6">
                            <img src={Chart} alt="Chart" className="img img-fluid chart-img my-3" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <div className="distribution-box">
                                <div className="dist-0"><span>Creator Reward 40%</span></div>
                                <div className="dist-1"><span>Ecosystem 15%</span></div>
                                <div className="dist-2"><span>Private Sales 10%</span></div>
                                <div className="dist-3"><span>LP 8%</span></div>
                                <div className="dist-4"><span>Marketing 7%</span></div>
                                <div className="dist-5"><span>Staking 5%</span></div>
                                <div className="dist-6"><span>Team & Advisors 5%</span></div>
                                <div className="dist-7"><span>Public Sales 5%</span></div>
                                <div className="dist-8"><span>Airdrop 5%</span></div>
                            </div>
                        </div>
                    </div>

                    <div className="row token-address">
                        <div className=" d-flex justify-content-center align-items-center">
                            <img src={Sol} alt="Solana" className='sol-img' />
                            <p className="address">Cki5smnh9piEQZTXNdc5pNzhetQJxWMnY3JmLtDLRSav</p>
                            <input id="token-address" type="text" value="Cki5smnh9piEQZTXNdc5pNzhetQJxWMnY3JmLtDLRSav" readOnly style={{ position: 'absolute', left: '-9999px' }} />
                            <button className='copy-btn' onClick={handleCopyToken}><i className="fa fa-copy"></i><span className={tokenCopied ? 'tooltip-show' : 'tooltip-hide'}>Copied!</span></button>
                        </div>
                        <div className="col-12 text-center"><p className='mb-0'>SOLANA Network Chain</p></div>
                    </div>

                </div>
            </section>

            <section id="launch-app">
                <div className="container">
                    <div className="section-title"><h2 className='text-center'>Create with Genius AI Game Engine, Earn Passive Income</h2></div>
                    <div className="row d-flex just-content-center align-items-center">
                        <div className="col-md-6 app text-center">
                            <p>At Genius AI Game, we're dedicated to providing AI-powered game engine solutions that optimize your business performance and elevate user experiences. Our team of experts is committed to delivering innovative technology and outstanding customer support, ensuring you unlock the full potential of our platform.</p>
                            <img src={LaunchApp} className='text-center app-img' alt="Launch APP" />
                        </div>
                        <div className="col-md-6 text-center">
                            <div class="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/GwEp6_EF2GU" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="feature">
                <div className="container">
                    <div className="subtitle text-center"><h3 className='text-white'>Unleashing Creativity</h3></div>
                    <div className="section-title"><h2 className='text-center'>Exploring the Genius of AI Game Engine</h2></div>
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6">
                            <div className="feature-item"><p>AI-Powered Game Creation</p></div>
                            <div className="feature-item"><p>NFT Integration</p></div>
                            <div className="feature-item"><p>Token Reward System</p></div>
                            <div className="feature-item"><p>Support for Various Game Formats</p></div>
                            <div className="feature-item"><p>Community and Sharing Features</p></div>
                        </div>
                        <div className="col-md-6">
                            <img src={FeatureImg} className='img img-fluid' alt="" />
                        </div>
                    </div>

                </div>
            </section>

            <section id="solutions">
                <div className="container">
                    <div className="section-title"><h2 className='text-center text-white'>Exclusive Games, Integrated Wallets, Enhanced Rewards</h2></div>
                    <div className="row">
                        <div className="col-12">
                            <img src={Screenshot} alt="" className="img img-fluid screenshot" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="solutions-box">
                                <span class="box-img"><img src={Payment} alt="Solutions Image" /></span>
                                <div className="box-text">
                                    <h6>Payment Options</h6>
                                    <p>Utilize $GAG & GENI tokens for transactions within the AI Game Engine Marketplace ecosystem, enabling seamless purchases of products and services.</p>
                                </div>
                            </div>
                            <div className="solutions-box">
                                <span class="box-img"><img src={NFT} alt="Solutions Image" /></span>
                                <div className="box-text">
                                    <h6>NFT Holder Benefits</h6>
                                    <p>Gain rewards during gameplay as an NFT holder, while creators enjoy revenue-sharing incentives.</p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="solutions-box">
                                <span class="box-img"><img src={Wallet} alt="Solutions Image" /></span>
                                <div className="box-text">
                                    <h6>Airdrop Wallet</h6>
                                    <p>Mine coins via the exclusive airdrop game wallet to amplify rewards.</p>
                                </div>
                            </div>
                            <div className="solutions-box">
                                <span class="box-img"><img src={Staking} alt="Solutions Image" /></span>
                                <div className="box-text">
                                    <h6>Staking Programs</h6>
                                    <p>Earn rewards through staking programs for NFT and GAG coin holders.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section id="roadmap">
                <div className="container">
                    <div className="section-title"><h2 className='text-center text-white'>Roadmap</h2></div>
                    <div className="row pos">
                        <div className="col-md-3 map map-1">
                            <h6>Phase 1</h6>
                            <ul>
                                <li>Website Launch</li>
                                <li>Whitepaper</li>
                                <li>Solana Scan Audit</li>
                                <li>Commencement of AI Game Engine Development</li>
                                <li>Private Sale</li>
                                <li>Initiation of AI Game Engine Development</li>
                            </ul>
                        </div>
                        <div className="col-md-3 map map-2">
                            <h6>Phase 2</h6>
                            <ul>
                                <li>Launch of Game Airdrop Wallet</li>
                                <li>Rollout of Creator Program</li>
                                <li>NFT and Staking Dashboard</li>
                                <li>Beta Open for AI Game Engine</li>
                                <li>Global Marketing Campaign</li>
                                <li>Listing on DEX and CEX</li>
                                <li>Launch on App Store and Play Store</li>
                            </ul>
                        </div>
                        <div className="col-md-3 map map-3">
                            <h6>Phase 3</h6>
                            <ul>
                                <li>Launch of AI Game Engine</li>
                                <li>DEX/Swap</li>
                                <li>Expansion of Partnerships, Celebrity Brand Ambassadors</li>
                                <li>Business Expansion</li>
                            </ul>
                        </div>
                        <div className="col-md-3 map map-4">
                            <h6>Phase 4</h6>
                            <ul>
                                <li>Improvement of User Interface</li>
                                <li>Metaverse Development and AI Integration</li>
                                <li>Ecosystem Expansion</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>

            <section id="partners">
                <div class="container">
                    <div class="section-title">
                        <h2 class="text-center text-white">Partners</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div class="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center">
                                <img src={Partner1} class="img m-2" alt="Partner" />
                                <img src={Partner2} class="img m-2" alt="Partner" />
                                <img src={Partner3} class="img m-2" alt="Partner" />
                                <img src={Partner4} class="img m-2" alt="Partner" />
                                <img src={Partner5} class="img m-2" alt="Partner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pre-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pre-1">
                            <h3>AI Gaming Reigns Supreme</h3>
                            <p>In AI Games We Trust: Pioneering Future Success</p>
                        </div>
                        <div className="col-12 mt-4 pre-2">
                            <p>The game engine offered by Genius AI Game will revolutionize the gaming industry with its Web3.0 blockchain-based approach</p>
                            <img src={PreFooter} alt="PreFooter" className='pre-footer-img' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;