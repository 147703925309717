import './Header.css';
import Logo from '../media/logo.png';
import X from '../media/x.png';
import Telegram from '../media/telegram.png';
import Medium from '../media/mediam.png';

function Header() {
    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-transparent">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={Logo} alt="Company Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#home">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#ecosystem">Ecosystem</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#tokenomics">Tokenomics</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#solutions">Solutions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#roadmap">Roadmap</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#partners">Partners</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="https://geniusaigame.gitbook.io/docs" target='_blank'>Whitepaper</a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav social ms-auto d-flex flex-row">
                            <li className="nav-item">
                                <a className="nav-link text-white" target='_blank' href="https://x.com/GAGcoin">
                                    <img src={X} alt="Twitter" width="20" height="20" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" target='_blank' href="https://medium.com/@geniusaigame">
                                    <img src={Medium} alt="Medium" width="20" height="20" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" target='_blank' href="https://t.me/Geniusaigame">
                                    <img src={Telegram} alt="Telegram" width="20" height="20" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;