import './Footer.css';
import Logo from '../media/logo.png';
import X from '../media/x.png';
import Telegram from '../media/telegram.png';
import Medium from '../media/mediam.png';

function Footer() {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Logo} alt="Logo" className="logo" />
                            <p className="text mt-4 pb-2">Empowering Gaming Futures with AI Engine and Web3.0.</p>
                            <p className='text'>For inquiries and contact information, please reach out to <a href="mailto:info@gag.game">info@gag.game</a></p>
                            <div className="social">
                                <div className="col-12">
                                    <ul className="nav navbar-nav social ms-auto d-flex flex-row">

                                        <li className="nav-item">
                                            <a className="nav-link text-white" target='_blank' href="https://x.com/GAGcoin">
                                                <img src={X} alt="Twitter" width="20" height="20" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" target='_blank' href="https://medium.com/@geniusaigame">
                                                <img src={Medium} alt="Medium" width="20" height="20" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-white" target='_blank' href="https://t.me/Geniusaigame">
                                                <img src={Telegram} alt="Telegram" width="20" height="20" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 menu d-flex align-items-center">
                            <ul>
                                <li><a href="#home">Home</a></li>
                                <li><a href="#ecosystem">Ecosystem</a></li>
                                <li><a href="#tokenomics">Tokenomics</a></li>
                                <li><a href="#solutions">Solutions</a></li>
                                <li><a href="#roadmap">Roadmap</a></li>
                                <li><a href="#partners">Partners</a></li>
                                <li><a href="https://geniusaigame.gitbook.io/docs" target='_blank'>Whitepaper</a></li>
                            </ul>
                        </div>
                        <div className="col-12 pt-md-4 pt-0 copyright"><p>&copy; Genius AI Game. All Rights Reserved.</p></div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;